import { createRouter, createWebHistory } from 'vue-router'
import MainVue from '@/views/Main.vue'
import index from '@/views/index1/index1'
import Axios from '../utils/http'

const routes = [

  {
    path: '/index',
    component: MainVue,
    meta: {

      title: "长晋路网保障运行大队"

    },
    beforeEnter:async (to, from,next) => {
      try {
              await Axios.get("/api/main/islogin")
              next()
            } catch (error) {
              if(error.response.status==401){
              localStorage.removeItem('mytoken')
              next("/login")
              }
            }
      
    },
    children: [
      {
        path: "",
        component: () => import("../views/home/Home.vue")
      },
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home/Home.vue")
      },
      {
        path: "/infoshow",
        name: "infoshow",
        component: () => import("../views/home/InfoShow.vue")
      },
      {
        path: "/fundlist",
        name: "fundlist",
        component: () => import("../views/fundlist/FundList.vue")
      },
      {
        path: "/wxlist",
        name: "wxlist",
        component: () => import("../views/wxlist/wxlist.vue")
      },
      {
        path: "/licenselist",
        name: "licenselist",
        component: () => import("../views/licenselist/licenselist.vue")
      },

    ]
  },

  {
    path: '/H5',
    name: 'H5index',
    component: () => import("../views/H5/H5index.vue"),
    meta: {
      title: "长晋路网保障运行大队"
    },
    beforeEnter:async (to, from,next) => {
      try {
              await Axios.get("/api/main/islogin")
              next()
            } catch (error) {
              if(error.response.status==401){
              localStorage.removeItem('mytoken')
              next("/H5Login")
              }
            }
    },
    children: [

      {
        path: "",
        component: () => import("../views/H5/pages/CaseAdd.vue")
      },
      {
        path: "/H5home",
        name: "H5Home",
        component: () => import("../views/H5/H5Home.vue"),
      },

      {
        path: "/H5CaseAdd",
        name: "CaseAdd",
        component: () => import("../views/H5/pages/CaseAdd.vue")
      },
      {
        path: "/H5CaseList",
        name: "CaseList",
        component: () => import("../views/H5/pages/CaseList.vue")
      },
      {
        path: "/H5Patrol",
        name: "H5Patrol",
        component: () => import("../views/H5/pages/H5Patrol.vue")
      },
      {
        path: "/H5User",
        name: "H5User",
        component: () => import("../views/H5/pages/H5User.vue")
      },



    ],
  },
  {
    path: "/H5search",
    name: "search",
    component: () => import("../views/H5/search.vue")
  },
  {
    path: "/H5Login",
    name: "H5Login",
    component: () => import("../views/H5/H5Login.vue")
  },
  {
    path: '/',
    component: index,
    meta: {
      title: "长晋路网保障运行大队"
    },

    children: [
      {
        path: "",
        component: () => import("../views/index1/photo.vue")
      },


      {
        path: "/aboutus",
        name: "aboutus",
        component: () => import("../views/index1/aboutus.vue")
      },
      {
        path: "/compensate",
        name: "compensate",
        component: () => import("../views/index1/compensate.vue")
      },
      {
        path: "/license",
        name: "license",
        component: () => import("../views/index1/license.vue")
      },
      {
        path: "/publicity",
        name: "publicity",
        component: () => import("../views/index1/publicity.vue")
      },

      {
        path: "/find",
        name: "find",
        component: () => import("../views/index1/find.vue")
      },
      {
        path: '/login',
        name: 'login',
        component: () => import("../views/login/Login.vue"),
      },


    ]
  },
  {
    path: '/register',
    name: 'register',
    component: () => import("../views/register/Register.vue"),
    beforeEnter:async (to, from,next) => {
      try {
              await Axios.get("/api/main/islogin")
              next()
            } catch (error) {
              if(error.response.status==401){
              localStorage.removeItem('mytoken')
              next("/login")
              }
            }
      
    },
    meta: {

      title: "长晋路网保障运行大队"

    },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notfound',
    component: () => import("../views/NotFound.vue"),
    meta: {

      title: "长晋路网保障运行大队"

    },
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {

  if (localStorage.isMoblie == "true"&&!to.fullPath.includes("/H5",0)) {
    next("/H5Login")
}
next()
  // let ruls = [
  //   "/login", '/index1', '/aboutus', '/compensate', '/license', '/publicity', "/find", "/findtest",
  //   "/H5Login", "/h5"
  // ]
  // if (ruls.indexOf(to.path) < 0) {
  //   // isLogin ? next() : next("/login")
  //   try {
  //     await Axios.get("/api/funds/islogin")
  //     next()
  //   } catch (error) {
  //     if(error.response.status==401){
  //     localStorage.removeItem('mytoken')
  //     next("/login")
  //     }

  //   }

  //   // console.log(p.name);

  // } else {
  //   if (to.meta.title) {
  //     document.title = to.meta.title
  //   }
  //   next()
  // }
})

export default router
