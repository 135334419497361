import axios from 'axios'
import { ElLoading, ElMessage } from 'element-plus'

let loading;
const startLoading = () => {
    loading = ElLoading.service({
        lock: true,
        text: "拼命加载中...",
        background: "rgba(0, 0, 0, .7)"
    })
}

const endLoading = () => {
    loading.close()
}
axios.defaults.timeout = 3000
// 请求拦截
axios.interceptors.request.use(config => {
    // 加载动画
    startLoading()

    if (localStorage.mytoken) {
        // 设置统一的请求头 header
        config.headers.Authorization = localStorage.mytoken
    }

    return config
}, error => {
    return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(response => {
    // 结束加载动画
    endLoading()
    return response
}, error => {
    // 错误提醒
    endLoading()
    ElMessage.error(error.response.data)

    // 获取错误状态码

    return Promise.reject(error)
})

export default axios