import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/less/index.less'
import ElementPlus, { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'
import Vant from 'vant'
import 'vant/lib/index.css'  //引入vant所有组件样式
import Axios from './utils/http'
// import Wechat from './utils/wechat.js'
const app = createApp(App)
// 给 axios 设置请求根路径

app.config.globalProperties.$axios = Axios
// app.config.globalProperties.$wechat = Wechat
app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$location ={};
localStorage.setItem("isMoblie", /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent));

app.use(ElementPlus)
app.use(Vant)
// Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

app.use(store).use(router).mount('#app')
// console.log(process.env.VUE_APP_BASE_URL);
