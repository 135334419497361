<template>
    <el-menu
      mode="horizontal"
      background-color=#547ef2 
      text-color="#fff"
      router
    >


        <el-menu-item index="/" >主页</el-menu-item >
        <el-menu-item index="/aboutus" >大队简介</el-menu-item >
        <el-menu-item index="/compensate" >路产赔偿</el-menu-item >
        <el-menu-item index="/publicity" >赔偿标准</el-menu-item >
        <el-menu-item index="/license" >涉路施工</el-menu-item >
        <el-menu-item index="/find" >案件查询</el-menu-item >
        <!-- <el-menu-item index="/findtest" >案件查询</el-menu-item > -->
        <el-menu-item index="/login" >管理</el-menu-item >
    </el-menu>

  </template>
  
  <script  setup>
  

  </script>
  <style scoped>
.el-menu {
	border-bottom:0!important;
}

  </style>